import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { mediaOverlay, imageWrapper } from "../styles/media.module.scss"

export default function CoverMedia(props) {

    const { itemData, className, imageClassName, style, keepHover, children } = props;

    const imageData = getImage(itemData.featuredImage?.node?.localFile)

    if (!imageData) return false


    let coverMediaClassName = `relative transition-all duration-200 group bg-transparent ${keepHover === false ? 'md:hover:bg-black md:active:bg-black' : 'hover:bg-black active:bg-black'} mb-0`;
    let imageMediaClassName = `border border-color-text`;

    if (className) coverMediaClassName = [coverMediaClassName, className].join(" ");
    if (imageClassName) imageMediaClassName = [imageMediaClassName, imageClassName].join(" ");

    return (
        <figure className={coverMediaClassName} style={style}>
            <GatsbyImage 
                image={imageData}
                alt={itemData.featuredImage.node.altText || itemData.title}
                layout={`fullWidth`}
                className={`${children ? 'cover-media-wrapper md:group-active:opacity-10 md:group-hover:opacity-10 dark:md:group-hover:opacity-5 dark:md:group-active:opacity-5 group-hover:opacity-80 group-active:opacity-80 transition-all duration-200' : ''} ${imageMediaClassName} ${keepHover === false ? imageWrapper : ''}`}
               />

                {children &&
                    <div className={`h-full w-full flex flex-col justify-start items-start absolute md:z-auto z-50 transition-all duration-200 top-0 right-0 left-0 bottom-0 opacity-0 invisible group-hover:visible group-active:visible group-hover:opacity-100 group-active:opacity-100 ${keepHover === false ? mediaOverlay : ''}`}>
                        <div className={`w-full`} style={{color: 'var(--text-contrast)'}}>
                            {children}
                        </div>

                    </div>
                
                }

        </figure>
    )
}

CoverMedia.defaultProps = {
    style: null,
    keepHover: false
}
