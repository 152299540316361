import * as React from 'react'
import Seo from "../components/Seo"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from "../components/PageWrapper"
import MasonryGallery from '../components/MasonryGallery'
import CoverMedia from '../components/CoverMedia'
import { PageTitle, ItemTitle } from '../components/PageTitles'

const ArtistPage = props => {

    const data = useStaticQuery(graphql`
    query ArtistPageQuery {
        allWpArtist(sort: {fields: slug, order: ASC}) {
            edges {
              node {
                slug
                title
                id
                streamingLinks {
                    youtubeartist
                    spotifyartist
                    soundcloudartist
                    pandoraartist
                    fieldGroupName
                    appleartist
                    amazonartist
                  }
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
            nodes {
                title
            }
          }
    }
  `)

    return (
        <Layout>
            <Seo title={`Artists`} description={`Current roster of Lighter Than Air artists, under management.`} />
            <PageWrapper scrollData={data.allWpArtist.nodes}>
                <PageTitle>MGMT. Artists</PageTitle>
                <MasonryGallery renderAs={`ul`} className={`p-0 -m-1.5`}>
                {
                        data.allWpArtist.edges.sort((a,b) => a.node.title > b.node.title).map(artist => {

                            return  (
                                <li className={`w-1/2 md:w-1/3 m-0 p-1.5`} key={artist.node.id}>
                                    <Link to={`/artists/${artist.node.slug}`} title={artist.node.title}>
                                    {artist.node.featuredImage &&
            
                                            <CoverMedia itemData={artist.node} className={`transition-all duration-200 hover:skew-y-1 hover:skew-x-1 hover:scale-[0.97] full-cover`} />
                                        }

                                        <ItemTitle className={`!mt-1`}>{artist.node.title}</ItemTitle>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </MasonryGallery>

            </PageWrapper>

        </Layout>
    )
}

export default ArtistPage