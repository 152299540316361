import * as React from 'react'

export const PageTitle = props => {

    const { as: Component, className, children, ...rest } = props;

    let pageTitleClassName = `-mb-2 sm:-mb-3 uppercase italic`;
    if (className) pageTitleClassName = [pageTitleClassName, className].join(" ");


    return (

        <div className={pageTitleClassName}>
            <div {...rest}>
                <Component className={`text-5xl sm:text-6xl leading-none`}>{children}</Component>
            </div>
        </div>
    )
}

PageTitle.defaultProps = {
    as: `h1`
}

export const SectionTitle = props => {

    const { as:Component, className, children, ...rest } = props;

    let sectionTitleClassName = `-mb-2 uppercase`;
    if (className) sectionTitleClassName = [sectionTitleClassName, className].join(" ");


    return (

        <div className={sectionTitleClassName}>

            <div {...rest}>
                <Component className={`text-4xl font-black leading-none italic`}>{children}</Component>
            </div>

        </div>
    )
}

SectionTitle.defaultProps = {
    as: `h2`
}

export const FancyTitle = props => {
    const { as:Component, className, children, ...rest } = props;

    let fancyTitleClassName = `text-7xl md:text-6xl lg:text-8xl xl:text-9xl uppercase font-secondary`;
    if (className) fancyTitleClassName = [fancyTitleClassName, className].join(" ");


    return (

        <div {...rest}>
            <Component className={fancyTitleClassName}>{children}</Component>
        </div>
    )
}

FancyTitle.defaultProps = {
    as: `h1`
}

export const Subheader = props => {

    const { as:Component, className, children} = props;

    const subheaderBaseClassName = `mb-2 text-base md:text-lg lg:text-xl tracking-wide uppercase`;
    
    let subheaderClassName = subheaderBaseClassName;
    if (className) subheaderClassName = [subheaderBaseClassName, className].join(" ");

    return (
        <Component className={subheaderClassName}> {children} </Component>
    )
}

Subheader.defaultProps = {
    as: `h3`
}

export const ItemTitle = props => {

    const { as:Component, className, children} = props;

    let itemTitleClassName = `leading-none mb-0 mt-2 uppercase text-center text-xl md:text-2xl`;
    if (className) itemTitleClassName = [itemTitleClassName, className].join(" ");

    return (
        <Component className={itemTitleClassName}> {children} </Component>
    )
}

ItemTitle.defaultProps = {
    as: `h2`
}