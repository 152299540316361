import * as React from 'react';
// import { Link } from 'gatsby';
import Masonry from 'react-masonry-component';
// import CoverMedia from './CoverMedia';

const masonryOptions = {
    transitionDuration: 0,
    percentPosition: true
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

class MasonryGallery extends React.Component {



    render() {

        const { className, children } = this.props;

        let masonryClassName = `m-0 p-0`;

        if (className) masonryClassName = className;
    
        return (
            <Masonry
                className={masonryClassName} // default ''
                elementType={this.props.renderAs} // default 'div'
                options={masonryOptions} // default {}
                disableImagesLoaded={false} // default false
                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                imagesLoadedOptions={imagesLoadedOptions} // default {}
            >
                {children}
            </Masonry>
        );
    }
}

MasonryGallery.defaultProps = {
    renderAs: `ul`,
    gutter: 0
}

export default MasonryGallery;